import React, { useState, useEffect } from 'react';
import {
  Row,
  Col,
  Form,
  Card,
  Space,
  Select,
  message,
  Image,
  DatePicker
} from 'antd';
import FormItem from '@/common/FormItem';
import styles from '@/common/Layout/index.module.less';
import CustomButton from '@/common/CustomButton';
import { history } from 'app/history';
// import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import LocalStorage from 'utilities/localStorage';
import Layout from '@/common/Layout';
import { getAllMaterialslist } from '@/features/stockCard/redux/slice';
import truck from '../../images/truck-delivery-done.gif';
import { getLoggedInUser } from 'utilities/helpers';
import moment from 'moment';
// const { RangePicker } = DatePicker;

const StockReportPage = () => {
  const [form] = Form.useForm();
  const { allMaterials } = useSelector((state) => state.stocks);

  const dispatch = useDispatch();

  const [batches, setBatches] = useState([]);
  const [search, setSearch] = useState('');
  const [ShowMaterials, SetShowMaterials] = useState(false);
  const [truckShow, setTruckShow] = useState(false);
  const [statement, setStatement] = useState('');
  // const [dateRange, setDateRange] = useState(moment());
  // const [code, setCode] = useState('');

  useEffect(() => {
    handleGetAllMaterialsList();
  }, [search]);

  const user = getLoggedInUser();
  const handleGetAllMaterialsList = () => {
    let payload = { search };
    dispatch(getAllMaterialslist(payload));
  };

  const onFinish = (values) => {
    const user = LocalStorage.getItem('Gravity_user');
    // const fromDate = values.fromDate
    //   ? `${moment(values.fromDate[0]._d).format('YYYY-MM-DD')}`
    //   : '';
    const toDate = values.todate
      ? `${moment(values.todate).format('YYYY-MM-DD')}`
      : '';
    setTruckShow(true);
    const authToken = user?.token;
    fetch(
      user?.user?.role == 'finance_user'
        ? `${process.env.REACT_APP_API_URL}/inventory-report?report_type=${values.report}&to_date=${toDate}`
        : `${process.env.REACT_APP_API_URL}/inventory-report?report_type=${values.report}&material_id=${values.material_id}&batch_details_id=${values.batch_details_id}`,
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/csv',
          Authorization: `Bearer ${authToken}`
        }
      }
    )
      .then((response) => response.blob())
      .then((blob) => {
        console.log(blob);
        const url = window.URL.createObjectURL(new Blob([blob]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `Stock_statement.csv`);
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);
        message.success('Report Downloaded successfully');
        form.resetFields();
        setTruckShow(false);
        // setDownloadReports(false);
      });
  };

  const onChange = (value, type) => {
    const material = allMaterials.filter(
      (MaterialsList) => MaterialsList.id === value
    );
    let statement_type =
      statement == 'disabled_consumption_stock' ? 'consumed' : '';
    let payload = { search: material[0].code, statement_type };
    dispatch(getAllMaterialslist(payload)).then((response) => {
      if (response?.payload?.success) {
        setBatches(response?.payload?.data[0]?.batch_details);
        // dispatch(getAllMaterialslist());
      }
    });
    form.setFieldsValue({ supplier_id: null });
    type == 'id'
      ? form.setFieldsValue({ material_name: value })
      : form.setFieldsValue({ material_id: value });
    // setCode(material[0]);
  };

  const onSearch = (value) => {
    setSearch(value);
  };

  const onChangeBatch = (value) => {
    const batches_details =
      value == 'all'
        ? allMaterials[0]?.batch_details
        : batches.filter((batches) => batches.id === value);
    setBatches(batches_details);
  };
  const onSearchBatch = (value) => {
    const batches_details = batches.inhouse_batch_number
      .toLowerCase()
      .includes(value.toLowerCase());
    setBatches(batches_details);
  };

  const handleonChange = (e) => {
    console.log(e);
    setStatement(e);
    e === 'material_inhouse_batch' || e === 'disabled_consumption_stock'
      ? SetShowMaterials(true)
      : SetShowMaterials(false);
  };
  return (
    <Layout sider={false}>
      <Form form={form} layout="vertical" onFinish={onFinish}>
        <Card title={'DOWNLOAD STOCK REPORT'} className={styles.CustomPanel}>
          <Row gutter={24}>
            <Col xs={24} xl={6} span={12} md={24} sm={24}>
              <FormItem
                label="Stock Statement"
                name="report"
                rules={[
                  {
                    required: true,
                    message: 'Select Statement'
                  }
                ]}
                className={styles.customLable}
              >
                <Select
                  placeholder="Please Select Statement"
                  onChange={(e) => handleonChange(e)}
                >
                  <option value="current_stock">Current Stock Statement</option>
                  <option value="material_inhouse_batch">
                    Material Wise Stock Statement
                  </option>
                  <option value="disabled_consumption_stock">
                    Consumed Stock
                  </option>
                  {user?.user?.role == 'finance_user' ? (
                    <option value="finance_stock">
                      Finance Stock Statement
                    </option>
                  ) : (
                    ''
                  )}
                </Select>
              </FormItem>
            </Col>
            {user?.user?.role == 'finance_user' &&
            statement == 'finance_stock' ? (
              <Col xs={24} xl={6} span={12} md={24} sm={24}>
                <FormItem
                  label="Select date"
                  name="todate"
                  rules={[
                    {
                      required: true,
                      message: 'Select From and To date'
                    }
                  ]}
                  className={styles.customLable}
                >
                  <DatePicker
                    // format={{
                    //   format: 'YYYY-MM-DD',
                    //   type: 'mask'
                    // }}
                    // onChange={(x) => {
                    //   setDateRange(x);
                    // }}
                    // value={dateRange}
                    // separator={'-'}
                    style={{ width: '100%' }}
                  />
                </FormItem>
              </Col>
            ) : (
              ''
            )}
            {ShowMaterials ? (
              <>
                <Col xs={24} xl={6} span={12} md={24} sm={24}>
                  <FormItem
                    label="Material Code"
                    name="material_id"
                    rules={[
                      {
                        required: true,
                        message: 'Select Material Code'
                      }
                    ]}
                    className={styles.customLable}
                  >
                    <Select
                      showSearch
                      placeholder="Select a material code"
                      optionFilterProp="children"
                      onChange={(e) => onChange(e, 'id')}
                      onSearch={onSearch}
                    >
                      {/* {allMaterials ? ( */}
                      {
                        allMaterials?.map((item, index) => {
                          return (
                            <option value={item.id} key={index}>
                              {item.code}
                            </option>
                          );
                        })
                        // ) : (
                        //   <Spin />
                        // )
                      }
                    </Select>
                  </FormItem>
                </Col>
                <Col xs={24} xl={6} span={12} md={24} sm={24}>
                  <FormItem
                    label="Material Name"
                    name="material_name"
                    rules={[
                      {
                        required: false,
                        message: 'Select Material Name'
                      }
                    ]}
                    className={styles.customLable}
                  >
                    <Select
                      showSearch
                      placeholder="Select a material name"
                      optionFilterProp="children"
                      onChange={(e) => onChange(e, 'name')}
                      onSearch={onSearch}
                    >
                      {allMaterials?.map((item, index) => {
                        return (
                          <option value={item.id} key={index}>
                            {item.name}
                          </option>
                        );
                      })}
                    </Select>
                    {/* <Input value={`${code?.name || 'NA'}`} disabled /> */}
                  </FormItem>
                </Col>
                <Col xs={24} xl={6} span={12} md={24} sm={24}>
                  <FormItem
                    label="In House Batch #"
                    name="batch_details_id"
                    rules={[
                      {
                        required: true,
                        message: 'Select In House Batch Number'
                      }
                    ]}
                    className={styles.customLable}
                  >
                    <Select
                      showSearch
                      placeholder="Select In House Batch Number"
                      optionFilterProp="children"
                      onChange={onChangeBatch}
                      onSearch={onSearchBatch}
                    >
                      {/* <option value="all">All</option> */}
                      {batches?.map((item, index) => {
                        return (
                          <option value={item.id} key={index}>
                            {item.inhouse_batch_number}
                          </option>
                        );
                      })}
                    </Select>
                  </FormItem>
                </Col>
              </>
            ) : (
              ''
            )}
          </Row>
        </Card>
        <br />
        <FormItem className="text-center">
          {truckShow ? (
            <Image
              src={truck}
              preview={false}
              style={{ width: '100px !important', height: '100px' }}
            />
          ) : (
            <Space>
              <CustomButton
                className={styles.inwardButton}
                htmlType="submit"
                type="primary"
              >
                Download
              </CustomButton>
              <CustomButton
                className={styles.inwardButton}
                type="primary"
                ghost
                onClick={() => history.back()}
              >
                Back
              </CustomButton>
            </Space>
          )}
        </FormItem>
      </Form>
    </Layout>
  );
};
export default StockReportPage;
