import React, { useEffect, useState } from 'react';
import {
  Row,
  Col,
  Form,
  message,
  Card,
  Space,
  Input,
  DatePicker,
  InputNumber,
  Radio
  // Select
} from 'antd';
import styles from '@/common/Layout/index.module.less';
import FormItem from '@/common/FormItem';
import { useDispatch, useSelector } from 'react-redux';
import {
  getDocsbyId
  // updateInvoice
} from '../../redux/slice';
import { useParams } from 'react-router-dom';
import moment from 'moment';
import Layout from '@/common/Layout';
import CustomButton from '@/common/CustomButton';
import { EditOutlined } from '@ant-design/icons';
import { getLoggedInUser, getRoles } from 'utilities/helpers';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
dayjs.extend(customParseFormat);
import weekday from 'dayjs/plugin/weekday';
import localeData from 'dayjs/plugin/localeData';
import { history } from 'app/history';
import VerificationModal from '../VerificationModal';
import ViewMaterialPage from '@/features/material/components/commonviewMaterialPage';

dayjs.extend(weekday);
dayjs.extend(localeData);
// const dateFormat = 'YYYY/MM/DD';
// const weekFormat = 'MM/DD';
// const monthFormat = 'YYYY/MM';
// import CustomButton  from '@/common/CustomButton';
const viewMaterialPage = ({ loading }) => {
  const params = useParams();
  const { material } = useSelector((state) => state.material);
  // console.log(material);
  const dispatch = useDispatch();
  const { user } = getLoggedInUser();
  const { Superadmindata } = getRoles('Warehouse');

  const [isinvoice, setIsinvoice] = useState(true);
  const [invoice, setInvoice] = useState(material?.invoice_number);
  const [isEdit, setIsEdit] = useState(false);
  const [containers, setContainers] = useState([]);
  const [isMatEdit, setIsMatEdit] = useState(false);
  const [isModal, setIsModal] = useState(false);
  const [materials, setMaterials] = useState();
  const [pagetype, setPagetype] = useState('');

  const [form] = Form.useForm();
  console.log(material?.no_of_bacthes_invoice, material?.batch_details?.length);
  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo.errorFields.length);
    if (errorInfo.errorFields.length > 0) {
      message.error(
        'Please select a response to all the questions in the checklist'
      );
    }
  };

  console.log(isinvoice, setInvoice);
  useEffect(() => {
    form.setFieldsValue({
      invoice_no: localStorage.getItem('invoice_num'),
      material_code: localStorage.getItem('material_code'),
      material_name: localStorage.getItem('material_name'),
      data_logger: material?.vehicle_storage_area?.vehicle_storage_area
        ? 'Yes'
        : 'No',
      labels_defaced: material?.vehicle_storage_area
        ?.vendor_status_label_defaced
        ? 'Yes'
        : 'No',
      dedusting: material?.vehicle_storage_area?.material_dedusting_status,
      seal_integrity: material?.vehicle_storage_area?.container_seal_integrity
    });
  }, []);
  console.log(invoice, isEdit, setIsinvoice, isMatEdit);
  useEffect(() => {
    handleGetDocsbyId();
  }, [isModal]);

  // const handleupdate = (id) => {
  //   containers['batch_id'] = id;
  //   containers['doc_id'] = params.id;
  //   containers['invoice_number'] = invoice;
  //   console.log(containers);
  //   dispatch(updateBatch(containers)).then((response) => {
  //     if (response?.payload?.success) {
  //       message.success(response?.payload?.message);
  //       history.push('/list');
  //     }
  //   });
  // };

  // const handleinvoice = () => {
  //   const payload = {
  //     doc_id: params.id,
  //     invoice_number: invoice
  //   };
  //   dispatch(updateInvoice(payload)).then((response) => {
  //     if (response?.payload?.success) {
  //       message.success(response?.payload?.message);
  //       history.push('/list');
  //     }
  //   });
  // };

  const handleCapacity = (e) => {
    const { name, value } = e.target;
    setMaterials({ ...materials, [name]: value });
  };

  const handleGetDocsbyId = () => {
    const payload = {
      id: params.id
    };
    dispatch(getDocsbyId(payload));
  };

  const handleEdit = (value) => {
    setContainers([]);
    setIsEdit({ [value]: true });
  };

  const handleEditMaterial = () => {
    setIsMatEdit(true);
  };

  const disabledDate = (current) => {
    return current && current > dayjs().endOf('day');
  };

  const dateFormat = 'YYYY-MM-DD';

  const handleChangegross = (index, e) => {
    console.log(e);
    const { name, value } = e.target;
    setContainers({ ...containers, [name]: value });
  };
  const handleChangedate = (name, date, dateString, index) => {
    console.log(index);
    console.log(date);
    // const list = [...containers];
    // list[index][name] = dateString;
    // setContainers(list);
    setContainers({ ...containers, [name]: dateString });
  };

  const handleStandardCancel = () => {
    history.push('/dashboard');
  };

  const handleVerification = (type, id) => {
    containers['id'] = id;
    //   containers['doc_id'] = params.id;
    //   containers['invoice_number'] = invoice;
    setPagetype(type);
    setIsModal(true);
  };
  return (
    <Layout sider={false}>
      <Form
        form={form}
        layout="vertical"
        // onFinish={onFinish}
        onFinishFailed={onFinishFailed}
      >
        {/* <Card
          title="MATERIAL DETAILS"
          className={styles.CustomPanel}
          // extra={
          //   user?.level == 3 ? (
          //     <CustomButton onClick={() => setIsinvoice(false)}>
          //       <EditOutlined />
          //     </CustomButton>
          //   ) : (
          //     ''
          //   )
          // }
        >
          {isinvoice ? (
            <Row gutter={24}>
              <Col xs={24} xl={8} span={8}>
                <p className={styles.customLable}>Material Code</p>
                <p className={styles.customValue}>{material?.material?.code}</p>
              </Col>
              <Col xs={24} xl={8} span={8}>
                <p className={styles.customLable}>Material Name</p>
                <p className={styles.customValue}>{material?.material?.name}</p>
              </Col>
              <Col xs={24} xl={8} span={8}>
                <p className={styles.customLable}>Invoice Number</p>
                <p className={styles.customValue}>{material?.invoice_number}</p>
              </Col>
            </Row>
          ) : (
            <Row gutter={24}>
              <Col xs={24} xl={8} span={8}>
                <FormItem label="Material Code" className={styles.customLable}>
                  <Input
                    placeholder="Enter Material code"
                    disabled
                    value={material?.material?.code}
                  />
                </FormItem>
              </Col>
              <Col xs={24} xl={8} span={8}>
                <FormItem
                  label="Material Name"
                  rules={[
                    {
                      required: false,
                      message: 'Enter Material name'
                    }
                  ]}
                  className={styles.customLable}
                >
                  <Input
                    placeholder="Enter Material name"
                    disabled
                    value={material?.material?.name}
                  />
                </FormItem>
              </Col>
              <Col xs={24} xl={8} span={8}>
                <FormItem label="Invoice Number" className={styles.customLable}>
                  <Input
                    placeholder="Enter Invoice number"
                    onChange={(e) => setInvoice(e.target.value)}
                    defaultValue={material?.invoice_number}
                  />
                </FormItem>
              </Col>
            </Row>
          )}
          {!isinvoice ? (
            <FormItem className="text-center">
              <Space>
                <CustomButton
                  onClick={() => history.back()}
                  loading={loading}
                  type="primary"
                  ghost
                >
                  Back
                </CustomButton>
                <CustomButton
                  onClick={() => handleinvoice()}
                  loading={loading}
                  type="primary"
                >
                  Update
                </CustomButton>
              </Space>
            </FormItem>
          ) : (
            ''
          )}
        </Card>
        <br /> */}
        <ViewMaterialPage />

        {/*<br />
        <Card title="DOCUMENT VERIFICATION" className={styles.CustomPanel}>
          <Row gutter={24}>
            <Col xs={24} xl={8} span={8}>
              <p className={styles.customLable}>
                Is the Material meant for Graviti?
              </p>
              <p className={styles.customValue}>
                {material?.for_graviti == true
                  ? 'Yes'
                  : material?.for_graviti == false
                  ? 'No'
                  : 'NA'}
              </p>
            </Col>
            <Col xs={24} xl={8} span={8}>
              <p className={styles.customLable}>
                Invoice/DC/BOE Number Available?
              </p>
              <p className={styles.customValue}>
                {material?.invoice_dc_boe_available == true
                  ? 'Yes'
                  : material?.invoice_dc_boe_available == false
                  ? 'No'
                  : 'NA'}
              </p>
            </Col>
            <Col xs={24} xl={8} span={8}>
              <p className={styles.customLable}>Purchase Order Available?</p>
              <p className={styles.customValue}>
                {material?.po_available == true
                  ? 'Yes'
                  : material?.po_available == false
                  ? 'No'
                  : 'NA'}
              </p>
            </Col>
            <Col xs={24} xl={8} span={8}>
              <p className={styles.customLable}>COA/COC, MSDS Available?</p>
              <p className={styles.customValue}>
                {material?.coa_coc_msda_available == true
                  ? 'Yes'
                  : material?.coa_coc_msda_available == false
                  ? 'No'
                  : 'NA'}
              </p>
            </Col>
            <Col xs={24} xl={8} span={8}>
              <p className={styles.customLable}>Material Vendor Status</p>
              <p className={styles.customValue}>
                {material?.material_vendor_status?.toUpperCase() || 'NA'}
              </p>
            </Col>
            <Col xs={24} xl={8} span={8}>
              <p className={styles.customLable}>
                Manufacturing site address matching with Provisionally
                Approved/Approved Vendor list
              </p>
              <p className={styles.customValue}>
                {material?.manufacture_site_address_matching == true
                  ? 'Yes'
                  : material?.manufacture_site_address_matching == false
                  ? 'No'
                  : 'NA'}
              </p>
            </Col>
          </Row>
        </Card>
        <br />
        <Card title="VEHICLE STORAGE AREA" className={styles.CustomPanel}>
          <Row gutter={24}>
            <Col xs={24} xl={8} span={8}>
              <p className={styles.customLable}>Vehicle Cleanliness</p>
              <p className={styles.customValue}>
                {material?.vehicle_storage_area?.vehicle_cleanliness == true
                  ? 'Yes'
                  : material?.vehicle_storage_area?.vehicle_cleanliness == false
                  ? 'No'
                  : 'NA'}
              </p>
            </Col>
            <Col xs={24} xl={8} span={8}>
              <p className={styles.customLable}>
                Storage In Enclosed Condition
              </p>
              <p className={styles.customValue}>
                {material?.vehicle_storage_area?.storage_enclose == true
                  ? 'Yes'
                  : material?.vehicle_storage_area?.storage_enclose == false
                  ? 'No'
                  : 'NA'}
              </p>
            </Col>
            <Col xs={24} xl={8} span={8}>
              <p className={styles.customLable}>Storage Condition Available</p>
              <p className={styles.customValue}>
                {material?.vehicle_storage_area?.storage_conditions == true
                  ? 'Yes'
                  : material?.vehicle_storage_area?.storage_conditions == false
                  ? 'No'
                  : 'NA'}
              </p>
            </Col>
          </Row>
        </Card> */}
        <br />
        {material?.batch_details?.map((item, index) => {
          return (
            <>
              <Card
                title={`BATCH ${index + 1}`}
                className={styles.CustomPanel}
                key={index}
                extra={
                  <>
                    <Space size={15}>
                      {
                        <CustomButton
                          size="small"
                          type="primary"
                          ghost
                          onClick={() =>
                            history.push(
                              `/damage-report?id=${params.id}&batch_id=${item.id}&type=view`
                            )
                          }
                        >
                          View Damage
                        </CustomButton>
                      }
                      {item?.weighing_done ? (
                        <CustomButton
                          size="small"
                          type="primary"
                          ghost
                          onClick={() =>
                            history.push(
                              `/weighing-report?id=${params.id}&batch_id=${item.id}`
                            )
                          }
                        >
                          View Weighing
                        </CustomButton>
                      ) : (
                        ''
                      )}
                      <CustomButton
                        className={styles.border_button}
                        onClick={() =>
                          history.push(`/test_request_form?id=${item?.id}`)
                        }
                        type="primary"
                        size="small"
                        ghost
                      >
                        Re-Generate TRF
                      </CustomButton>
                      {user?.level == 3 || Superadmindata?.[0]?.level == 3 ? (
                        // <CustomButton
                        //   className={styles.border_button}
                        //   onClick={() => handleEdit(index)}
                        //   type="primary"
                        //   size="small"
                        //   ghost
                        // >
                        <EditOutlined
                          className={styles.border_button}
                          onClick={() => handleEdit(index)}
                          type="primary"
                          size="small"
                          ghost
                        />
                      ) : (
                        ''
                      )}
                      <span
                        onClick={() => window.open(item?.pdf_doc, '_blank')}
                        style={{ cursor: 'pointer' }}
                      >
                        Print label
                      </span>
                      <a href={`/update-net-weights/${item?.mrn_number}`}>
                        {item?.mrn_number}
                      </a>
                    </Space>
                  </>
                }
              >
                {!isEdit[index] ? (
                  <Row gutter={24}>
                    <Col xs={24} xl={12} span={12}>
                      <p className={styles.customLable}>Mfg.Batch/lot no</p>
                      <p className={styles.customValue}>
                        {item?.mfg_batch_lot_no || 'NA'}
                      </p>
                    </Col>

                    <Col xs={24} xl={12} span={12}>
                      <p className={styles.customLable}>Manufacturing date</p>
                      <p className={styles.customValue}>
                        {' '}
                        {item?.mfg_date
                          ? moment(item?.mfg_date).format('DD MMM YYYY')
                          : 'NA'}
                      </p>
                    </Col>
                    <Col xs={24} xl={12} span={12}>
                      <p className={styles.customLable}>
                        Manufacturing re-test date
                      </p>
                      <p className={styles.customValue}>
                        {item?.mfg_retest_date
                          ? moment(item?.mfg_retest_date).format('DD MMM YYYY')
                          : 'NA'}
                      </p>
                    </Col>
                    <Col xs={24} xl={12} span={12}>
                      <p className={styles.customLable}>
                        Manufacturing expiry date
                      </p>
                      <p className={styles.customValue}>
                        {item?.mfg_expiry_date
                          ? moment(item?.mfg_expiry_date).format('DD MMM YYYY')
                          : 'NA'}
                      </p>
                    </Col>
                    <Col xs={24} xl={12} span={12}>
                      <p className={styles.customLable}>Inhouse expiry date</p>
                      <p className={styles.customValue}>
                        {item?.in_house_expiry_date
                          ? moment(item?.in_house_expiry_date).format(
                              'DD MMM YYYY'
                            )
                          : 'NA'}
                      </p>
                    </Col>
                    <Col xs={24} xl={12} span={12}>
                      <p className={styles.customLable}>Inhouse batch number</p>
                      <p className={styles.customValue}>
                        {item?.inhouse_batch_number || 'NA'}
                      </p>
                    </Col>

                    {console.log('Item: ', item)}

                    <Col xs={24} xl={12} span={12}>
                      <p className={styles.customLable}>
                        No. of Containers/packs received
                      </p>
                      <p className={styles.customValue}>
                        {item?.containers_packs_received}
                      </p>
                    </Col>
                    <Col xs={24} xl={12} span={12}>
                      <p className={styles.customLable}>Invoice Quantity</p>
                      <p className={styles.customValue}>
                        {item?.invoice_quantity
                          ? parseFloat(item?.invoice_quantity).toFixed(3)
                          : 'NA'}
                      </p>
                    </Col>
                    <Col xs={24} xl={12} span={12}>
                      <p className={styles.customLable}>Received Quantity</p>
                      <p className={styles.customValue}>
                        {item?.received_quantity
                          ? parseFloat(item?.received_quantity).toFixed(3)
                          : 'NA'}
                      </p>
                    </Col>
                    <Col xs={24} xl={12} span={24}>
                      <p className={styles.customLable}>UOM</p>
                      <p className={styles.customValue}>
                        {material?.material?.uom || 'NA'}
                      </p>
                    </Col>

                    <Col xs={24} xl={12} span={24}>
                      <p className={styles.customLable}>Batch Unit Rate</p>
                      <p className={styles.customValue}>
                        {item?.batch_unit_rate || 'NA'}
                      </p>
                    </Col>
                    <Col xs={24} xl={12} span={24}>
                      <p className={styles.customLable}>Batch Total Price</p>
                      <p className={styles.customValue}>
                        {item?.batch_total_price || 'NA'}
                      </p>
                    </Col>
                    <Col xs={24} xl={12} span={24}>
                      <p className={styles.customLable}>Test to be performed</p>
                      <p className={styles.customValue}>
                        {item?.test_to_be_performed || 'NA'}
                      </p>
                    </Col>
                    <Col xs={24} xl={12} span={24}>
                      <p className={styles.customLable}>Focus MRN #</p>
                      <p className={styles.customValue}>
                        {item?.focus_mrn_no || 'NA'}
                      </p>
                    </Col>
                    <Col xs={24} xl={12} span={24}>
                      <p className={styles.customLable}>
                        Focus Batch Unit Rate
                      </p>
                      <p className={styles.customValue}>
                        {item?.focus_batchunit_rate || 'NA'}
                      </p>
                    </Col>
                    <Col xs={24} xl={24} span={24}>
                      <p className={styles.customLable}>Comments</p>
                      <p className={styles.customValue}>
                        {item?.comments || 'NA'}
                      </p>
                    </Col>
                  </Row>
                ) : (
                  <>
                    <Row gutter={24}>
                      <Col xs={24} xl={12} span={12}>
                        <FormItem
                          label="Mfg.Batch/Lot no"
                          rules={[
                            {
                              required: true,
                              message: 'Enter Batch number'
                            }
                          ]}
                          className={styles.customLable}
                        >
                          <Input
                            placeholder="Enter Batch Number"
                            defaultValue={item?.mfg_batch_lot_no}
                            onChange={(e) =>
                              handleChangegross(index, {
                                target: {
                                  name: 'mfg_batch_lot_no',
                                  value: e.target.value
                                }
                              })
                            }
                          />
                        </FormItem>
                      </Col>
                      <Col xs={24} xl={12} span={12}>
                        <FormItem
                          label="Manufacturing date"
                          rules={[
                            {
                              required: false,
                              message: 'Select date'
                            }
                          ]}
                          className={styles.customLable}
                        >
                          <DatePicker
                            placeholder="Select Date"
                            style={{ width: '100%' }}
                            disabledDate={disabledDate}
                            // name="mfg_Date"
                            onChange={(date, dateString) =>
                              handleChangedate(
                                'mfg_date',
                                date,
                                dateString,
                                index
                              )
                            }
                            defaultValue={
                              item?.mfg_date
                                ? dayjs(item?.mfg_date, dateFormat)
                                : ''
                            }
                          />
                        </FormItem>
                      </Col>
                      <Col xs={24} xl={12} span={12}>
                        <FormItem
                          label="Mfg Re-test Date"
                          rules={[
                            {
                              required: false,
                              message: 'Select Mfg re-test date'
                            }
                          ]}
                          className={styles.customLable}
                        >
                          <DatePicker
                            placeholder="Select Date"
                            style={{ width: '100%' }}
                            defaultValue={
                              item?.mfg_retest_date
                                ? dayjs(item?.mfg_retest_date, 'YYYY-MM-DD')
                                : ''
                            }
                            // name="mfg_retest_Date"
                            onChange={(date, dateString) =>
                              handleChangedate(
                                'mfg_retest_date',
                                date,
                                dateString,
                                index
                              )
                            }
                          />
                        </FormItem>
                      </Col>
                      <Col xs={24} xl={12} span={12}>
                        <FormItem
                          label="Mfg Expiry Date"
                          rules={[
                            {
                              required: false,
                              message: 'Select MFR expiry date'
                            }
                          ]}
                          className={styles.customLable}
                        >
                          <DatePicker
                            placeholder="Select Date"
                            style={{ width: '100%' }}
                            defaultValue={
                              item?.mfg_expiry_date
                                ? dayjs(item?.mfg_expiry_date, 'YYYY-MM-DD')
                                : ''
                            }
                            // name="mfg_expiry_Date"
                            onChange={(date, dateString) =>
                              handleChangedate(
                                'mfg_expiry_date',
                                date,
                                dateString,
                                index
                              )
                            }
                          />
                        </FormItem>
                      </Col>
                      <Col xs={24} xl={12} span={12}>
                        <FormItem
                          label="In house Expiry date"
                          rules={[
                            {
                              required: false,
                              message: 'Select In-House expiry date'
                            }
                          ]}
                          className={styles.customLable}
                        >
                          <DatePicker
                            format={dateFormat}
                            placeholder="Select Date"
                            style={{ width: '100%' }}
                            defaultValue={
                              item?.in_house_expiry_date
                                ? dayjs(
                                    item?.in_house_expiry_date,
                                    'YYYY-MM-DD'
                                  )
                                : ''
                            }
                            // name="mfg_expiry_Date"
                            onChange={(date, dateString) =>
                              handleChangedate(
                                'in_house_expiry_date',
                                date,
                                dateString,
                                index
                              )
                            }
                          />
                        </FormItem>
                      </Col>
                      <Col xs={24} xl={12} span={12}>
                        <FormItem
                          label="No. of Containers/packs received"
                          rules={[
                            {
                              required: true,
                              message: 'Enter No. of containers'
                            }
                          ]}
                          className={styles.customLable}
                        >
                          <InputNumber
                            name="containers_packs_received"
                            min={1}
                            max={100000}
                            style={{
                              width: '100%'
                            }}
                            placeholder="Enter No. of Containers"
                            value={item?.containers_packs_received}
                            disabled
                            // onChange={(e) =>
                            //   handleChangegross(i, {
                            //     target: {
                            //       name: 'containers_packs_received',
                            //       value: e
                            //     }
                            //   })
                            // }
                          />
                        </FormItem>
                      </Col>
                      <Col xs={16} xl={12} span={12} md={16} sm={16}>
                        <FormItem
                          label="Invoice Quantity"
                          rules={[
                            {
                              required: true,
                              message: 'Enter Invoice Quantity'
                            }
                          ]}
                          className={styles.customLable}
                        >
                          <InputNumber
                            min={0}
                            placeholder="Enter Invoice Quantity"
                            style={{
                              width: '100%'
                            }}
                            defaultValue={item?.invoice_quantity}
                            onChange={(e) =>
                              handleChangegross(index, {
                                target: {
                                  name: 'invoice_quantity',
                                  value: e
                                }
                              })
                            }
                          />
                        </FormItem>
                      </Col>
                      <Col xs={8} xl={12} span={12} md={8} sm={8}>
                        <FormItem
                          label="UOM"
                          rules={[
                            {
                              required: false,
                              message: 'Enter UOM'
                            }
                          ]}
                          className={styles.customLable}
                        >
                          <Input
                            placeholder="Enter UOM"
                            disabled
                            value={material?.material?.uom}
                          />
                        </FormItem>
                      </Col>
                      <Col xs={16} xl={12} span={12} md={16} sm={16}>
                        <FormItem
                          label="Received Quantity"
                          rules={[
                            {
                              required: true,
                              message: 'Enter Received Quantity'
                            }
                          ]}
                          className={styles.customLable}
                        >
                          <InputNumber
                            min={0}
                            placeholder="Enter Received Quantity"
                            style={{
                              width: '100%'
                            }}
                            defaultValue={item?.received_quantity}
                            onChange={(e) =>
                              handleChangegross(index, {
                                target: {
                                  name: 'received_quantity',
                                  value: e
                                }
                              })
                            }
                          />
                        </FormItem>
                      </Col>
                      <Col xs={8} xl={12} span={12} md={8} sm={8}>
                        <FormItem
                          label="UOM"
                          rules={[
                            {
                              required: false,
                              message: 'Enter UOM'
                            }
                          ]}
                          className={styles.customLable}
                        >
                          <Input
                            placeholder="Enter UOM"
                            disabled
                            value={material?.material?.uom}
                          />
                        </FormItem>
                      </Col>
                      <Col xs={16} xl={12} span={12} md={16} sm={16}>
                        <FormItem
                          label="Batch Unit Rate"
                          rules={[
                            {
                              required: true,
                              message: 'Enter Unit Rate'
                            }
                          ]}
                          className={styles.customLable}
                        >
                          <InputNumber
                            min={0}
                            placeholder="Enter Batch Unit Rate"
                            style={{
                              width: '100%'
                            }}
                            defaultValue={item?.batch_unit_rate}
                            onChange={(e) =>
                              handleChangegross(index, {
                                target: {
                                  name: 'batch_unit_rate',
                                  value: e
                                }
                              })
                            }
                          />
                        </FormItem>
                      </Col>
                      <Col xs={16} xl={12} span={12} md={16} sm={16}>
                        <FormItem
                          label={`Batch Total Price ${containers?.[index]?.batch_unit_rate} ${containers?.[index]?.received_quantity}`}
                          rules={[
                            {
                              required: true,
                              message: 'Enter Total'
                            }
                          ]}
                          className={styles.customLable}
                        >
                          <Input
                            // min={0}
                            placeholder="Enter Total Price"
                            style={{
                              width: '100%'
                            }}
                            value={
                              containers?.batch_unit_rate ||
                              containers?.received_quantity
                                ? parseFloat(
                                    containers?.batch_unit_rate ||
                                      item?.batch_unit_rate
                                  ) *
                                  parseFloat(
                                    containers?.received_quantity ||
                                      item?.received_quantity
                                  )
                                : item?.batch_total_price
                            }
                            disabled
                            // onChange={(e) =>
                            //   handleChangegross(index, {
                            //     target: {
                            //       name: 'batch_total_price',
                            //       value: e
                            //     }
                            //   })
                            // }
                          />
                        </FormItem>
                      </Col>
                    </Row>
                    <FormItem className="text-center">
                      <Space>
                        <CustomButton
                          htmlType="button"
                          onClick={handleStandardCancel}
                          className={styles.border_button}
                        >
                          Cancel
                        </CustomButton>

                        <CustomButton
                          type="primary"
                          // htmlType="submit"
                          loading={loading}
                          className={styles.border_button}
                          onClick={() =>
                            handleVerification('viewbatch', item?.id)
                          }
                          ghost
                        >
                          Initiate Verification
                        </CustomButton>
                      </Space>
                    </FormItem>
                    {/* <FormItem className="text-center">
                      <Space>
                        <CustomButton
                          className={styles.border_button}
                          onClick={() => history.back()}
                          loading={loading}
                          type="primary"
                          ghost
                        >
                          Back
                        </CustomButton>
                        <CustomButton
                          className={styles.border_button}
                          onClick={() => handleupdate(item?.id)}
                          loading={loading}
                          type="primary"
                        >
                          Update
                        </CustomButton>
                      </Space>
                    </FormItem> */}
                  </>
                )}
              </Card>
              <br />
            </>
          );
        })}
        {/* <br /> */}
        <Card
          title="MATERIAL VERIFICATION"
          className={styles.CustomPanel}
          extra={
            user?.level == 3 || Superadmindata?.[0]?.level == 3 ? (
              <EditOutlined
                className={styles.border_button}
                onClick={() => handleEditMaterial()}
                type="primary"
                size="small"
                ghost
              />
            ) : (
              ''
            )
          }
        >
          {isMatEdit ? (
            <>
              <Row gutter={24}>
                <Col xs={24} xl={8} span={8}>
                  <FormItem
                    label="Data logger received along with consignment"
                    name="data_logger"
                    rules={[
                      {
                        required: true,
                        message: 'Choose Yes/No'
                      }
                    ]}
                    className={styles.customLable}
                  >
                    <Radio.Group
                      onChange={handleCapacity}
                      className={styles.customRadio}
                      name="data_logger"
                    >
                      <Radio value="Yes">Yes</Radio>
                      <Radio value="No">No</Radio>
                    </Radio.Group>
                  </FormItem>
                </Col>
                <Col xs={24} xl={8} span={8}>
                  <FormItem
                    label="Vendor status label defaced"
                    name="labels_defaced"
                    rules={[
                      {
                        required: true,
                        message: 'Choose Yes/No'
                      }
                    ]}
                    className={styles.customLable}
                  >
                    <Radio.Group
                      onChange={handleCapacity}
                      className={styles.customRadio}
                      name="labels_defaced"
                    >
                      <Radio value="Yes">Yes</Radio>
                      <Radio value="No">No</Radio>
                    </Radio.Group>
                  </FormItem>
                </Col>
                <Col xs={24} xl={8} span={8}>
                  <FormItem
                    label="Material dedusting status"
                    name="dedusting"
                    rules={[
                      {
                        required: true,
                        message: 'Choose Yes/No'
                      }
                    ]}
                    className={styles.customLable}
                  >
                    <Radio.Group
                      onChange={handleCapacity}
                      className={styles.customRadio}
                      name="dedusting"
                    >
                      <Radio value="Yes">Performed</Radio>
                      <Radio value="No">Not performed</Radio>
                    </Radio.Group>
                  </FormItem>
                </Col>
                <Col xs={24} xl={8} span={8}>
                  <FormItem
                    label="Seal integrity of the containers"
                    name="seal_integrity"
                    rules={[
                      {
                        required: true,
                        message: 'Choose Yes/No'
                      }
                    ]}
                    className={styles.customLable}
                  >
                    <Radio.Group
                      onChange={handleCapacity}
                      className={styles.customRadio}
                      name="seal_integrity"
                    >
                      <Radio value="Yes">Satisfactory</Radio>
                      <Radio value="No">Not satisfactory</Radio>
                    </Radio.Group>
                  </FormItem>
                </Col>
                {/* <Col xs={24} xl={12} span={12} md={24} sm={24}>
                  <FormItem
                    label="Test to be Performed"
                    name="test_to_be_performed"
                    rules={[
                      {
                        required: true,
                        message: 'Select any test to be performed'
                      }
                    ]}
                    className={styles.customLable}
                  >
                    <Select
                      placeholder="Select Test to be performed"
                      onChange={handleCapacity}
                      name="test_to_be_performed"
                    >
                      <option value="Identification Test">
                        Identification Test
                      </option>
                      <option value="Complete Analysis">
                        Complete Analysis
                      </option>
                    </Select>
                  </FormItem>
                </Col> */}
              </Row>
              <FormItem className="text-center">
                <Space>
                  <CustomButton
                    htmlType="button"
                    onClick={handleStandardCancel}
                    className={styles.border_button}
                  >
                    Cancel
                  </CustomButton>

                  <CustomButton
                    type="primary"
                    // htmlType="submit"
                    loading={loading}
                    className={styles.border_button}
                    onClick={() => handleVerification('materialVerification')}
                    ghost
                  >
                    Initiate Verification
                  </CustomButton>
                </Space>
              </FormItem>
            </>
          ) : (
            <Row gutter={24}>
              <Col xs={24} xl={8} span={8}>
                <p className={styles.customLable}>
                  Data logger received along with consignment
                </p>
                <p className={styles.customValue}>
                  {material?.vehicle_storage_area?.data_logger_received
                    ? 'Yes'
                    : 'No'}
                </p>
              </Col>
              <Col xs={24} xl={8} span={8}>
                <p className={styles.customLable}>
                  Vendor status label defaced
                </p>
                <p className={styles.customValue}>
                  {material?.vehicle_storage_area?.vendor_status_label_defaced
                    ? 'Yes'
                    : 'No'}
                </p>
              </Col>
              <Col xs={24} xl={8} span={8}>
                <p className={styles.customLable}>Material dedusting status</p>
                <p className={styles.customValue}>
                  {material?.vehicle_storage_area?.material_dedusting_status
                    ? 'Yes'
                    : 'No'}
                </p>
              </Col>
              {/* <Col xs={24} xl={8} span={8}>
                <p className={styles.customLable}>
                  Seal integrity of the containers
                </p>
                <p className={styles.customValue}>
                  {material?.vehicle_storage_area?.container_seal_integrity ==
                  'Yes'
                    ? 'Satisfactory'
                    : 'Not satisfactory'}
                </p>
              </Col> */}
              {/* <Col xs={24} xl={12} span={12} md={24} sm={24}>
                <p className={styles.customLable}>Test to be Performed</p>
                <p className={styles.customValue}>
                  {material?.batch_details?.[0]?.test_to_be_performed || 'NA'}
                </p>
              </Col> */}
            </Row>
          )}
        </Card>
      </Form>
      {isModal ? (
        <VerificationModal
          isModal={isModal}
          materials={materials}
          setIsModal={setIsModal}
          material={material}
          setIsEdit={setIsEdit}
          page={pagetype}
          editableBatch={containers}
        />
      ) : (
        ''
      )}
    </Layout>
  );
};
export default viewMaterialPage;
