import React, { useEffect } from 'react';
import styles from '@/common/Layout/index.module.less';
import { Card, Empty, Tag } from 'antd';
import CustomTable from 'common/Content/CustomTable';
import moment from 'moment';
import CustomButton from 'common/CustomButton';
import {
  // CloudUploadOutlined,
  CloudDownloadOutlined
  // PlusOutlined
} from '@ant-design/icons';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
// import { Link } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import { getAllQCMaterials } from '../../redux/slice';

// const { Search } = Input;
// const filters = [
// {
//   name: 'All',
//   value: ''
// }
// {
//   name: 'Approved',
//   value: 'approved'
// },
// {
//   name: 'Pending',
//   value: 'pending'
// }
// ];
const TestRequestListPage = () => {
  const searchParam = useLocation().search;
  const dispatch = useDispatch();
  const searchParams = new URLSearchParams(searchParam);
  const main_content =
    searchParams.get('qc') === 'doc_ver_pending'
      ? 'Approval for Missing Document'
      : searchParams.get('qc') === 'veh_ver_pending'
      ? 'Approval for Storage Inspection Failure'
      : searchParams.get('qc') === 'dmg_ver_pending'
      ? 'Approval for Damages Reported'
      : 'Approval for Discrepency Raised';
  const { allqcMaterials, pagination, getAllMaterilLoading } = useSelector(
    (state) => state.qc
  );
  console.log(allqcMaterials);
  // const [radiofilter, setRadiofilter] = useState('');
  const [page, setPage] = useState('');

  useEffect(() => {
    handleGetAllMaterials();
  }, [page]);
  const handleGetAllMaterials = () => {
    let damage = searchParams.get('qc') == 'dmg_ver_pending' ? 'true' : '';
    let status =
      searchParams.get('qc') == 'dmg_ver_pending' ? '' : searchParams.get('qc');
    let payload = {
      status: status,
      damage: damage,
      page
    };
    console.log('test');
    dispatch(getAllQCMaterials(payload));
  };
  const columns = [
    // {
    //   title: 'Pending Receipts ID',
    //   dataIndex: 'mrn_number',
    //   key: 'mrn_number',
    //   width: '150px',
    //   ellipsis: true,
    //   sorter: (a, b) => a?.mrn_number?.localeCompare(b?.mrn_number),
    //   render: (text, record) => {
    //     return <a href={`view/${record.id}`}>{text}</a>;
    //   },
    //   responsive: ['xl', 'xs', 'sm', 'xxl', 'md']
    // },
    {
      title: 'Invoice no',
      dataIndex: 'invoice_number',
      key: 'invoice_number',
      ellipsis: true,
      width: '100px',
      sorter: (a, b) => a?.invoice_number?.localeCompare(b?.invoice_number),
      render: (text, record) => {
        return (
          <a
            href={
              record.qa_status === 'doc_ver_pending'
                ? `doc-missing/${record.id}`
                : searchParams.get('qc') === 'veh_ver_pending'
                ? `storage-missing/${record.id}`
                : searchParams.get('qc') === 'dmg_ver_pending'
                ? `qc-damage-report/${record.id}`
                : searchParams.get('qc') === 'wh_ver_pending'
                ? `qc-discrepancy-report/${record.id}`
                : '#'
            }
            onClick={() => {
              localStorage.setItem('invoice_num', record.invoice_number),
                localStorage.setItem('material_name', record?.material?.name),
                localStorage.setItem('material_code', record?.material?.code);
            }}
          >
            {text}
          </a>
        );
      },
      responsive: ['xl', 'xs', 'sm', 'xxl', 'md']
    },
    {
      title: 'Material name',
      dataIndex: 'material_name',
      key: 'material_name',
      ellipsis: true,
      width: '100px',
      sorter: (a, b) => a?.material_name?.localeCompare(b?.material_name),
      render: (text, record) => {
        return record?.material?.name || 'NA';
      },
      responsive: ['xl', 'xs', 'sm', 'xxl', 'md']
    },
    {
      title: 'Material code',
      dataIndex: 'material_code',
      key: 'material_code',
      ellipsis: true,
      width: '100px',
      sorter: (a, b) => a?.material_code?.localeCompare(b?.material_code),
      render: (text, record) => {
        return record?.material?.code || 'NA';
      },
      responsive: ['xl', 'xs', 'sm', 'xxl', 'md']
    },
    {
      title: 'PO no',
      dataIndex: 'po_number',
      key: 'po_number',
      ellipsis: true,
      width: '100px',
      sorter: (a, b) => a?.po_number?.localeCompare(b?.po_number),
      render: (text, record) => {
        return record?.po_number || 'NA';
      },
      responsive: ['xl', 'xs', 'sm', 'xxl', 'md']
    },
    {
      title: 'Date of Receipts',
      dataIndex: 'createdAt',
      key: 'createdAt',
      align: 'center',
      width: '100px',
      sorter: (a, b) =>
        moment(a[`createdAt`]).unix() - moment(b[`createdAt`]).unix(),
      render: (createdAt) => <>{moment(createdAt).format('DD MMM YYYY')}</>,
      responsive: ['xl', 'xs', 'sm', 'xxl', 'md']
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      width: 120,
      ellipsis: true,
      sorter: (a, b) => a?.status?.localeCompare(b?.status),
      render: (text, record) => {
        return (
          <Tag
            color={
              record?.damages.some((item) => item.status === 'QA Pending')
                ? '#2db7f5'
                : 'Green'
            }
          >
            {record?.damages.some((item) => item.status === 'QA Pending')
              ? 'Need to take action'
              : 'Action taken'}
          </Tag>
        );
      },
      responsive: ['xl', 'xs', 'sm', 'xxl', 'md']
    }
  ];
  let locale = {
    emptyText: (
      <span>
        <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
        <div className={styles.empty_state_text}>
          There are no material requests to display
        </div>
      </span>
    )
  };
  return (
    <>
      <h2 className={`${styles.page_title}`}>Overview</h2>
      <h4 className={`${styles.page_sub_title}`}>
        View material requests and their current status
      </h4>
      <br />
      <Card
        className={`${styles.cardStyles_approver}`}
        title={main_content}
        extra={
          <CustomButton
            size="small"
            icon={<CloudDownloadOutlined />}
            // className={`${styles.CustomButton} `}
            type="primary"
          >
            <span>Export report</span>
          </CustomButton>
        }
      >
        <div className={`${styles.table_container}`}>
          <CustomTable
            locale={locale}
            loading={getAllMaterilLoading}
            columns={columns}
            dataSource={allqcMaterials ? allqcMaterials : []}
            pagination={{
              size: 'small',
              total: pagination?.total_records || 0,
              showSizeChanger: false,
              onChange: (page) => {
                setPage(page);
              }
            }}
            // className={styles.scrolling}
            scroll={{ x: 'auto' }}
          />
        </div>
      </Card>
    </>
  );
};

export default TestRequestListPage;
