import React, { useState, useEffect } from 'react';
import {
  Row,
  Col,
  Form,
  Input,
  // DatePicker,
  // Select,
  Radio,
  Space,
  Image,
  message,
  Card,
  Tag
} from 'antd';
import FormItem from '@/common/FormItem';
import styles from '@/common/Layout/index.module.less';
import CustomButton from '@/common/CustomButton';
import truck from '@/features/generateMrn/components/images/truck-delivery-done.gif';
import { useDispatch, useSelector } from 'react-redux';
import { history } from 'app/history';
import Layout from '@/common/Layout';
import { useParams } from 'react-router-dom';
import { getAllQCMaterials, damage_report_qc } from '../../redux/slice';
import { CheckCircleOutlined } from '@ant-design/icons';

const { TextArea } = Input;
const { CheckableTag } = Tag;
const DamageReportPage = ({ loading }) => {
  const params = useParams();
  const dispatch = useDispatch();
  const { allqcMaterials } = useSelector((state) => state.qc);
  const [form] = Form.useForm();

  const [selectedTags, setSelectedTags] = useState([]);
  const [comments, setComments] = useState();
  const [container, setContainer] = useState();
  const [damagedContainers, setDamagedContainers] = useState([]);
  const [truckShow, setTruckShow] = useState(false);
  const [damagedQty, setDamagedQty] = useState();

  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo.errorFields.length);
    if (errorInfo.errorFields.length > 0) {
      message.error(
        'Please select a response to all the questions in the checklist'
      );
    }
  };
  console.log(localStorage.getItem('inv_num'));
  const handleClickSubmit = () => {
    setTruckShow(true);
    const payload = {
      document_id: params.id,
      damage_containers: damagedContainers
    };
    dispatch(damage_report_qc(payload)).then(function (response) {
      if (response?.payload?.success) {
        setTruckShow(false);
        message.success(response?.payload?.message);
        history.back();
      }
    });
  };

  // const handleStandardCancel = () => {
  //   history.push('/dashboard');
  // };
  const handleComments = (e) => {
    setComments(e.target.value);
  };
  const handleCapacity = (e) => {
    setContainer(e.target.value);
  };
  useEffect(() => {
    form.setFieldsValue({
      invoice_no: localStorage.getItem('invoice_num'),
      material_code: localStorage.getItem('material_code'),
      material_name: localStorage.getItem('material_name')
    });
  }, [damagedContainers]);
  const batch_containers = allqcMaterials?.damages?.damaged_containers;
  // const containers = batch_containers;
  // const containers = batch_containers?.length
  //   ? batch_containers.filter(
  //       (batch_containers) => batch_containers !== selectedTags
  //     )
  //   : [];
  console.log(damagedContainers, 'damagedContainers');
  console.log(batch_containers);
  const containers = batch_containers?.length
    ? batch_containers.filter((batch_containers) =>
        damagedContainers.every(
          (person2) =>
            !person2.container_number.includes(
              batch_containers?.container_number
            ) && person2.status === 'QA Pending'
        )
      )
    : [];
  console.log(containers);
  const handleChange = (tag, checked, qty) => {
    const nextSelectedTags = checked
      ? tag
      : selectedTags.filter((t) => t !== tag);
    console.log('You are interested in: ', nextSelectedTags);
    setSelectedTags(nextSelectedTags);
    setDamagedQty(qty);
  };

  useEffect(() => {
    handleGetAllMaterials();
  }, []);
  const handleGetAllMaterials = () => {
    let payload = {
      id: params.id
    };
    dispatch(getAllQCMaterials(payload));
  };
  console.log(allqcMaterials);
  console.log(selectedTags);
  const handleClick = () => {
    setDamagedContainers([
      ...damagedContainers,
      {
        container_number: selectedTags,
        comments: comments,
        is_accepted: container,
        status: container === 'yes' ? 'Approved' : 'Rejected',
        damaged_quantity: damagedQty
      }
    ]);
    form.resetFields();
  };
  console.log(damagedContainers?.length);
  console.log(damagedContainers);
  return (
    <Layout sider={false}>
      <Form
        form={form}
        layout="vertical"
        // onFinish={onFinish}
        onFinishFailed={onFinishFailed}
      >
        <Card
          title="APPROVAL FOR DAMAGES REPORTED"
          className={styles.CustomPanel}
        >
          <Row gutter={24}>
            <Col xs={24} xl={8} span={8}>
              <FormItem
                label="Material Code"
                name="material_code"
                rules={[
                  {
                    required: false,
                    message: 'Enter Material code'
                  }
                ]}
                className={styles.customLable}
              >
                <Input placeholder="Enter Material code" disabled />
              </FormItem>
            </Col>
            <Col xs={24} xl={8} span={8}>
              <FormItem
                label="Material Name"
                name="material_name"
                rules={[
                  {
                    required: false,
                    message: 'Enter Material name'
                  }
                ]}
                className={styles.customLable}
              >
                <Input placeholder="Enter Material name" disabled />
              </FormItem>
            </Col>
            <Col xs={24} xl={8} span={8}>
              <FormItem
                label="Invoice Number"
                name="invoice_no"
                rules={[
                  {
                    required: false,
                    message: 'Enter Invoice number'
                  }
                ]}
                className={styles.customLable}
              >
                <Input placeholder="Enter Invoice number" disabled />
              </FormItem>
            </Col>
          </Row>
        </Card>

        <Card title="DAMAGED CONTAINERS" className={styles.CustomPanel}>
          {containers?.length ? (
            <>
              <Row gutter={24}>
                <Col span={24} xs={24} xl={24} md={24} sm={24}>
                  {containers?.map((tag, i) =>
                    tag?.status !== 'QA Pending' ? (
                      <Tag
                        icon={<CheckCircleOutlined />}
                        key={i}
                        className={styles.customTag}
                      >
                        {`${tag.container_number} ${
                          tag.damaged_quantity
                            ? `(${tag.damaged_quantity})`
                            : ''
                        }`}
                      </Tag>
                    ) : (
                      <CheckableTag
                        key={tag}
                        disabled={true}
                        checked={selectedTags.includes(tag?.container_number)}
                        onChange={(checked) =>
                          handleChange(
                            tag?.container_number,
                            checked,
                            tag?.damaged_quantity
                          )
                        }
                        className={styles.checkbleTags}
                      >
                        {`${tag.container_number} ${
                          tag.damaged_quantity
                            ? `(${tag.damaged_quantity})`
                            : ''
                        }`}
                      </CheckableTag>
                    )
                  )}
                </Col>
              </Row>
              <br />
              {selectedTags?.length ? (
                <Row gutter={24}>
                  <Col span={24}>
                    <FormItem
                      label="Comments"
                      name="comments"
                      rules={[
                        {
                          required: false,
                          message: 'Enter comments'
                        }
                      ]}
                      className={styles.customLable}
                    >
                      <TextArea
                        placeholder="Please enter comments"
                        name="comments"
                        onChange={(e) => handleComments(e)}
                      />
                    </FormItem>
                  </Col>
                  <Col xs={24} xl={8} span={8}>
                    <FormItem
                      name="proceed"
                      rules={[
                        {
                          required: true,
                          message: 'Choose Yes/No'
                        }
                      ]}
                      className={styles.customLable}
                    >
                      <Radio.Group
                        onChange={handleCapacity}
                        className={styles.customRadio}
                        name="proceed"
                      >
                        <Radio value="yes">Approved</Radio>
                        <Radio value="no">Reject</Radio>
                      </Radio.Group>
                    </FormItem>
                  </Col>
                </Row>
              ) : (
                ''
              )}
            </>
          ) : (
            ''
          )}
          {damagedContainers?.length
            ? damagedContainers?.map((item, index) => {
                return (
                  <Tag
                    icon={<CheckCircleOutlined />}
                    key={index}
                    className={styles.customTag}
                  >
                    {`${item.container_number} ${
                      item.damaged_quantity ? `(${item.damaged_quantity})` : ''
                    }`}
                  </Tag>
                );
              })
            : ''}
        </Card>

        <br />
        <FormItem className="text-center">
          <Space>
            {containers?.length === 0 ? (
              truckShow ? (
                <Image
                  src={truck}
                  preview={false}
                  style={{ width: '100px !important', height: '100px' }}
                />
              ) : (
                <CustomButton
                  type="primary"
                  onClick={handleClickSubmit}
                  loading={loading}
                >
                  Confirm
                </CustomButton>
              )
            ) : (
              <CustomButton
                type="primary"
                onClick={handleClick}
                loading={loading}
              >
                Submit
              </CustomButton>
            )}
          </Space>
        </FormItem>
        {/* </Space> */}
      </Form>
    </Layout>
  );
};
export default DamageReportPage;
